/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * SG Apply Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApplicantOrgDto
 */
export interface ApplicantOrgDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicantOrgDto
     */
    'orgId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantOrgDto
     */
    'orgName'?: string;
    /**
     * 
     * @type {Array<SfPermission>}
     * @memberof ApplicantOrgDto
     */
    'permissions'?: Array<SfPermission>;
}
/**
 * 
 * @export
 * @interface ApplicantOrgMemberDto
 */
export interface ApplicantOrgMemberDto {
    /**
     * 
     * @type {UserDto}
     * @memberof ApplicantOrgMemberDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicantOrgMemberDto
     */
    'submit'?: boolean;
    /**
     * 
     * @type {Array<SfPermission>}
     * @memberof ApplicantOrgMemberDto
     */
    'permissions'?: Array<SfPermission>;
}
/**
 * 
 * @export
 * @interface ApplicantOrgMemberListDto
 */
export interface ApplicantOrgMemberListDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicantOrgMemberListDto
     */
    'totalRows'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantOrgMemberListDto
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantOrgMemberListDto
     */
    'previousStartIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantOrgMemberListDto
     */
    'currentStartIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantOrgMemberListDto
     */
    'nextStartIndex'?: number;
    /**
     * 
     * @type {Array<ApplicantOrgMemberDto>}
     * @memberof ApplicantOrgMemberListDto
     */
    'data'?: Array<ApplicantOrgMemberDto>;
    /**
     * 
     * @type {Array<GroupMappingDto>}
     * @memberof ApplicantOrgMemberListDto
     */
    'groupMapping'?: Array<GroupMappingDto>;
}
/**
 * 
 * @export
 * @interface CurrencyDto
 */
export interface CurrencyDto {
    /**
     * 
     * @type {string}
     * @memberof CurrencyDto
     */
    'symbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyDto
     */
    'formattedAmount'?: string;
}
/**
 * 
 * @export
 * @interface FormDto
 */
export interface FormDto {
    /**
     * 
     * @type {number}
     * @memberof FormDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GrantAppDetailDto
 */
export interface GrantAppDetailDto {
    /**
     * 
     * @type {boolean}
     * @memberof GrantAppDetailDto
     */
    'archived'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GrantAppDetailDto
     */
    'removed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'application'?: string;
    /**
     * 
     * @type {number}
     * @memberof GrantAppDetailDto
     */
    'applicationId'?: number;
    /**
     * 
     * @type {Array<GrantAppDetailDto>}
     * @memberof GrantAppDetailDto
     */
    'childApps'?: Array<GrantAppDetailDto>;
    /**
     * 
     * @type {Array<FormDto>}
     * @memberof GrantAppDetailDto
     */
    'childForms'?: Array<FormDto>;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'round'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'projectTitle'?: string;
    /**
     * 
     * @type {number}
     * @memberof GrantAppDetailDto
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'orgOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'instanceOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'instanceLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'applicantName'?: string;
    /**
     * 
     * @type {Array<ResponseDto>}
     * @memberof GrantAppDetailDto
     */
    'responses'?: Array<ResponseDto>;
    /**
     * 
     * @type {UserPermissionDto}
     * @memberof GrantAppDetailDto
     */
    'userPermission'?: UserPermissionDto;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'shareExpiry'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'shareNote'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GrantAppDetailDto
     */
    'shareExpired'?: boolean;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof GrantAppDetailDto
     */
    'shares'?: Array<UserDto>;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'sitePublicUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof GrantAppDetailDto
     */
    'roundFormId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDetailDto
     */
    'publicStageName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GrantAppDetailDto
     */
    'displayStartVariationLink'?: boolean;
}
/**
 * 
 * @export
 * @interface GrantAppDto
 */
export interface GrantAppDto {
    /**
     * 
     * @type {boolean}
     * @memberof GrantAppDto
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDto
     */
    'application'?: string;
    /**
     * 
     * @type {number}
     * @memberof GrantAppDto
     */
    'applicationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDto
     */
    'round'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDto
     */
    'projectTitle'?: string;
    /**
     * 
     * @type {number}
     * @memberof GrantAppDto
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDto
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDto
     */
    'orgOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDto
     */
    'instanceOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDto
     */
    'instanceLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDto
     */
    'submitDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrantAppDto
     */
    'status'?: GrantAppDtoStatusEnum;
    /**
     * 
     * @type {UserPermissionDto}
     * @memberof GrantAppDto
     */
    'userPermission'?: UserPermissionDto;
    /**
     * 
     * @type {CurrencyDto}
     * @memberof GrantAppDto
     */
    'totalAmountRequested'?: CurrencyDto;
    /**
     * 
     * @type {boolean}
     * @memberof GrantAppDto
     */
    'shared'?: boolean;
    /**
     * 
     * @type {SubsequentAppStatusDto}
     * @memberof GrantAppDto
     */
    'subsequentAppStatus'?: SubsequentAppStatusDto;
    /**
     * 
     * @type {TimeZoneDto}
     * @memberof GrantAppDto
     */
    'timeZone'?: TimeZoneDto;
    /**
     * 
     * @type {Array<SearchHighlightDto>}
     * @memberof GrantAppDto
     */
    'highlight'?: Array<SearchHighlightDto>;
    /**
     * 
     * @type {Array<GrantAppDto>}
     * @memberof GrantAppDto
     */
    'childApps'?: Array<GrantAppDto>;
    /**
     * 
     * @type {Array<FormDto>}
     * @memberof GrantAppDto
     */
    'childForms'?: Array<FormDto>;
}

export const GrantAppDtoStatusEnum = {
    SUBMITTED: 'SUBMITTED',
    IN_PROGRESS: 'IN_PROGRESS',
    INCOMPLETE: 'INCOMPLETE'
} as const;

export type GrantAppDtoStatusEnum = typeof GrantAppDtoStatusEnum[keyof typeof GrantAppDtoStatusEnum];

/**
 * 
 * @export
 * @interface GrantAppTinyDto
 */
export interface GrantAppTinyDto {
    /**
     * 
     * @type {number}
     * @memberof GrantAppTinyDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GrantAppTinyDto
     */
    'header'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GroupEnum = {
    READER: 'READER',
    WRITER: 'WRITER',
    AUTHOR: 'AUTHOR',
    ADMIN: 'ADMIN'
} as const;

export type GroupEnum = typeof GroupEnum[keyof typeof GroupEnum];


/**
 * 
 * @export
 * @interface GroupMappingDto
 */
export interface GroupMappingDto {
    /**
     * 
     * @type {GroupEnum}
     * @memberof GroupMappingDto
     */
    'key'?: GroupEnum;
    /**
     * 
     * @type {string}
     * @memberof GroupMappingDto
     */
    'value'?: string;
}


/**
 * 
 * @export
 * @interface InstanceDto
 */
export interface InstanceDto {
    /**
     * 
     * @type {number}
     * @memberof InstanceDto
     */
    'instanceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceDto
     */
    'instanceName'?: string;
}
/**
 * 
 * @export
 * @interface ResponseDto
 */
export interface ResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseDto
     */
    'responseId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseDto
     */
    'formId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    'formName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    'submitDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    'status'?: ResponseDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    'lastUpdateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    'lastUpdateBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseDto
     */
    'lastUpdateById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    'sitePublicUrl'?: string;
    /**
     * 
     * @type {CurrencyDto}
     * @memberof ResponseDto
     */
    'totalAmountRequested'?: CurrencyDto;
    /**
     * 
     * @type {TimeZoneDto}
     * @memberof ResponseDto
     */
    'timeZone'?: TimeZoneDto;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    'formPurpose'?: ResponseDtoFormPurposeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    'variationPublicId'?: string;
}

export const ResponseDtoStatusEnum = {
    SUBMITTED: 'SUBMITTED',
    IN_PROGRESS: 'IN_PROGRESS',
    NOT_STARTED: 'NOT_STARTED',
    INCOMPLETE: 'INCOMPLETE'
} as const;

export type ResponseDtoStatusEnum = typeof ResponseDtoStatusEnum[keyof typeof ResponseDtoStatusEnum];
export const ResponseDtoFormPurposeEnum = {
    APPLICATION: 'APPLICATION',
    ASSESSMENT: 'ASSESSMENT',
    MANAGEMENT: 'MANAGEMENT',
    EXTERNAL: 'EXTERNAL',
    VARIATION: 'VARIATION'
} as const;

export type ResponseDtoFormPurposeEnum = typeof ResponseDtoFormPurposeEnum[keyof typeof ResponseDtoFormPurposeEnum];

/**
 * 
 * @export
 * @interface SearchHighlightDto
 */
export interface SearchHighlightDto {
    /**
     * 
     * @type {string}
     * @memberof SearchHighlightDto
     */
    'key'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchHighlightDto
     */
    'value'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SfPermission = {
    ADMIN: 'ADMIN',
    GRANT_APP_CREATE: 'GRANT_APP_CREATE',
    GRANT_APP_READ: 'GRANT_APP_READ',
    GRANT_APP_SUBMIT: 'GRANT_APP_SUBMIT',
    GRANT_APP_WRITE: 'GRANT_APP_WRITE',
    MAYBE_SUBMIT: 'MAYBE_SUBMIT',
    OF_VIEW: 'OF_VIEW'
} as const;

export type SfPermission = typeof SfPermission[keyof typeof SfPermission];


/**
 * 
 * @export
 * @interface ShareApplicationDto
 */
export interface ShareApplicationDto {
    /**
     * 
     * @type {Array<SimpleIdDto>}
     * @memberof ShareApplicationDto
     */
    'users'?: Array<SimpleIdDto>;
    /**
     * 
     * @type {string}
     * @memberof ShareApplicationDto
     */
    'expiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareApplicationDto
     */
    'shareNote'?: string;
}
/**
 * 
 * @export
 * @interface SimpleIdDto
 */
export interface SimpleIdDto {
    /**
     * 
     * @type {number}
     * @memberof SimpleIdDto
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SimpleIdNameDto
 */
export interface SimpleIdNameDto {
    /**
     * 
     * @type {number}
     * @memberof SimpleIdNameDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleIdNameDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SubmissionListDto
 */
export interface SubmissionListDto {
    /**
     * 
     * @type {number}
     * @memberof SubmissionListDto
     */
    'totalRows'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionListDto
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionListDto
     */
    'previousStartIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionListDto
     */
    'currentStartIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionListDto
     */
    'nextStartIndex'?: number;
    /**
     * 
     * @type {Array<GrantAppDto>}
     * @memberof SubmissionListDto
     */
    'data'?: Array<GrantAppDto>;
}
/**
 * 
 * @export
 * @interface SubsequentAppStatusDto
 */
export interface SubsequentAppStatusDto {
    /**
     * 
     * @type {string}
     * @memberof SubsequentAppStatusDto
     */
    'respStatus'?: SubsequentAppStatusDtoRespStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SubsequentAppStatusDto
     */
    'respDueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubsequentAppStatusDto
     */
    'respSubmitDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubsequentAppStatusDto
     */
    'respIsVariationNotInVariationStage'?: boolean;
}

export const SubsequentAppStatusDtoRespStatusEnum = {
    SUBMITTED: 'SUBMITTED',
    IN_PROGRESS: 'IN_PROGRESS',
    NOT_STARTED: 'NOT_STARTED',
    INCOMPLETE: 'INCOMPLETE'
} as const;

export type SubsequentAppStatusDtoRespStatusEnum = typeof SubsequentAppStatusDtoRespStatusEnum[keyof typeof SubsequentAppStatusDtoRespStatusEnum];

/**
 * 
 * @export
 * @interface TimeZoneDto
 */
export interface TimeZoneDto {
    /**
     * 
     * @type {string}
     * @memberof TimeZoneDto
     */
    'shortName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeZoneDto
     */
    'longName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeZoneDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'smartyFileURL'?: string;
    /**
     * 
     * @type {Array<ApplicantOrgDto>}
     * @memberof UserDto
     */
    'applicantOrgs'?: Array<ApplicantOrgDto>;
    /**
     * 
     * @type {Array<InstanceDto>}
     * @memberof UserDto
     */
    'instances'?: Array<InstanceDto>;
}
/**
 * 
 * @export
 * @interface UserPermissionDto
 */
export interface UserPermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissionDto
     */
    'deleteAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissionDto
     */
    'archiveAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissionDto
     */
    'changeUserAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissionDto
     */
    'shareUserAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissionDto
     */
    'assignToOrgAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissionDto
     */
    'removeOrgAllowed'?: boolean;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary archive app
         * @param {number} submissionId id
         * @param {boolean} archive archive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveApp: async (submissionId: number, archive: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('archiveApp', 'submissionId', submissionId)
            // verify required parameter 'archive' is not null or undefined
            assertParamExists('archiveApp', 'archive', archive)
            const localVarPath = `/submissions/{submissionId}/archive/{archive}`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)))
                .replace(`{${"archive"}}`, encodeURIComponent(String(archive)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary assign org
         * @param {number} submissionId id
         * @param {string} orgId org id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignOrg: async (submissionId: number, orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('assignOrg', 'submissionId', submissionId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('assignOrg', 'orgId', orgId)
            const localVarPath = `/submissions/{submissionId}/org/{orgId}`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)))
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApp: async (submissionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('deleteApp', 'submissionId', submissionId)
            const localVarPath = `/submissions/{submissionId}`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary download response pdf
         * @param {number} responseId id
         * @param {boolean} [translate] translate response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPdf: async (responseId: number, translate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseId' is not null or undefined
            assertParamExists('downloadPdf', 'responseId', responseId)
            const localVarPath = `/submissions/responses/{responseId}/pdf`
                .replace(`{${"responseId"}}`, encodeURIComponent(String(responseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get app details
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApp: async (submissionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('getApp', 'submissionId', submissionId)
            const localVarPath = `/submissions/{submissionId}`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get app summary
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSummary: async (submissionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('getAppSummary', 'submissionId', submissionId)
            const localVarPath = `/submissions/{submissionId}/summary`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get classie org types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassieOrgTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/submissions/classieOrgTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of members for share or changeuser
         * @param {number} submissionId id
         * @param {GetMembersOperationEnum} operation operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers: async (submissionId: number, operation: GetMembersOperationEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('getMembers', 'submissionId', submissionId)
            // verify required parameter 'operation' is not null or undefined
            assertParamExists('getMembers', 'operation', operation)
            const localVarPath = `/submissions/{submissionId}/members`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (operation !== undefined) {
                localVarQueryParameter['operation'] = operation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/submissions/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get user submissions
         * @param {number} [startIndex] 
         * @param {GetSubmissionsSortByEnum} [sortBy] 
         * @param {GetSubmissionsSortOrderEnum} [sortOrder] 
         * @param {boolean} [archive] 
         * @param {number} [page] 
         * @param {string} [keyword] 
         * @param {string} [orgId] 
         * @param {number} [status] 
         * @param {string} [round] 
         * @param {string} [ownedBy] 
         * @param {string} [sharedWith] 
         * @param {string} [formName] 
         * @param {string} [response] 
         * @param {string} [submittedDateFrom] 
         * @param {string} [submittedDateTo] 
         * @param {string} [dueDateFrom] 
         * @param {string} [dueDateTo] 
         * @param {boolean} [isSimpleSearch] 
         * @param {number} [instanceId] 
         * @param {number} [classieOrgId] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmissions: async (startIndex?: number, sortBy?: GetSubmissionsSortByEnum, sortOrder?: GetSubmissionsSortOrderEnum, archive?: boolean, page?: number, keyword?: string, orgId?: string, status?: number, round?: string, ownedBy?: string, sharedWith?: string, formName?: string, response?: string, submittedDateFrom?: string, submittedDateTo?: string, dueDateFrom?: string, dueDateTo?: string, isSimpleSearch?: boolean, instanceId?: number, classieOrgId?: number, region?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/submissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (archive !== undefined) {
                localVarQueryParameter['archive'] = archive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (round !== undefined) {
                localVarQueryParameter['round'] = round;
            }

            if (ownedBy !== undefined) {
                localVarQueryParameter['ownedBy'] = ownedBy;
            }

            if (sharedWith !== undefined) {
                localVarQueryParameter['sharedWith'] = sharedWith;
            }

            if (formName !== undefined) {
                localVarQueryParameter['formName'] = formName;
            }

            if (response !== undefined) {
                localVarQueryParameter['response'] = response;
            }

            if (submittedDateFrom !== undefined) {
                localVarQueryParameter['submittedDateFrom'] = submittedDateFrom;
            }

            if (submittedDateTo !== undefined) {
                localVarQueryParameter['submittedDateTo'] = submittedDateTo;
            }

            if (dueDateFrom !== undefined) {
                localVarQueryParameter['dueDateFrom'] = dueDateFrom;
            }

            if (dueDateTo !== undefined) {
                localVarQueryParameter['dueDateTo'] = dueDateTo;
            }

            if (isSimpleSearch !== undefined) {
                localVarQueryParameter['isSimpleSearch'] = isSimpleSearch;
            }

            if (instanceId !== undefined) {
                localVarQueryParameter['instanceId'] = instanceId;
            }

            if (classieOrgId !== undefined) {
                localVarQueryParameter['classieOrgId'] = classieOrgId;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get current user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/submissions/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of instances the user has applied for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInstances: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/submissions/users/instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove the org from the submission
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrg: async (submissionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('removeOrg', 'submissionId', submissionId)
            const localVarPath = `/submissions/{submissionId}/org`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary share app
         * @param {number} submissionId id
         * @param {ShareApplicationDto} shareApplicationDto user Ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareApp: async (submissionId: number, shareApplicationDto: ShareApplicationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('shareApp', 'submissionId', submissionId)
            // verify required parameter 'shareApplicationDto' is not null or undefined
            assertParamExists('shareApp', 'shareApplicationDto', shareApplicationDto)
            const localVarPath = `/submissions/{submissionId}/share`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareApplicationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Variation Response for the given GrantApp
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startVariationForm: async (submissionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('startVariationForm', 'submissionId', submissionId)
            const localVarPath = `/submissions/{submissionId}/variation`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary change user
         * @param {number} submissionId id
         * @param {SimpleIdDto} simpleIdDto user Ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwner: async (submissionId: number, simpleIdDto: SimpleIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('updateOwner', 'submissionId', submissionId)
            // verify required parameter 'simpleIdDto' is not null or undefined
            assertParamExists('updateOwner', 'simpleIdDto', simpleIdDto)
            const localVarPath = `/submissions/{submissionId}/owner`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simpleIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary archive app
         * @param {number} submissionId id
         * @param {boolean} archive archive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveApp(submissionId: number, archive: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveApp(submissionId, archive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.archiveApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary assign org
         * @param {number} submissionId id
         * @param {string} orgId org id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignOrg(submissionId: number, orgId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignOrg(submissionId, orgId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.assignOrg']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary delete
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApp(submissionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApp(submissionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary download response pdf
         * @param {number} responseId id
         * @param {boolean} [translate] translate response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPdf(responseId: number, translate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPdf(responseId, translate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.downloadPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary get app details
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApp(submissionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GrantAppDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApp(submissionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary get app summary
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSummary(submissionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GrantAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSummary(submissionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAppSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary get classie org types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassieOrgTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleIdNameDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassieOrgTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getClassieOrgTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary list of members for share or changeuser
         * @param {number} submissionId id
         * @param {GetMembersOperationEnum} operation operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembers(submissionId: number, operation: GetMembersOperationEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicantOrgMemberListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembers(submissionId, operation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getMembers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary get regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getRegions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary get user submissions
         * @param {number} [startIndex] 
         * @param {GetSubmissionsSortByEnum} [sortBy] 
         * @param {GetSubmissionsSortOrderEnum} [sortOrder] 
         * @param {boolean} [archive] 
         * @param {number} [page] 
         * @param {string} [keyword] 
         * @param {string} [orgId] 
         * @param {number} [status] 
         * @param {string} [round] 
         * @param {string} [ownedBy] 
         * @param {string} [sharedWith] 
         * @param {string} [formName] 
         * @param {string} [response] 
         * @param {string} [submittedDateFrom] 
         * @param {string} [submittedDateTo] 
         * @param {string} [dueDateFrom] 
         * @param {string} [dueDateTo] 
         * @param {boolean} [isSimpleSearch] 
         * @param {number} [instanceId] 
         * @param {number} [classieOrgId] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubmissions(startIndex?: number, sortBy?: GetSubmissionsSortByEnum, sortOrder?: GetSubmissionsSortOrderEnum, archive?: boolean, page?: number, keyword?: string, orgId?: string, status?: number, round?: string, ownedBy?: string, sharedWith?: string, formName?: string, response?: string, submittedDateFrom?: string, submittedDateTo?: string, dueDateFrom?: string, dueDateTo?: string, isSimpleSearch?: boolean, instanceId?: number, classieOrgId?: number, region?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubmissions(startIndex, sortBy, sortOrder, archive, page, keyword, orgId, status, round, ownedBy, sharedWith, formName, response, submittedDateFrom, submittedDateTo, dueDateFrom, dueDateTo, isSimpleSearch, instanceId, classieOrgId, region, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getSubmissions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary get current user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary list of instances the user has applied for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInstances(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstanceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInstances(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getUserInstances']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Remove the org from the submission
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOrg(submissionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOrg(submissionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.removeOrg']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary share app
         * @param {number} submissionId id
         * @param {ShareApplicationDto} shareApplicationDto user Ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareApp(submissionId: number, shareApplicationDto: ShareApplicationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareApp(submissionId, shareApplicationDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.shareApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create a Variation Response for the given GrantApp
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startVariationForm(submissionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startVariationForm(submissionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.startVariationForm']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary change user
         * @param {number} submissionId id
         * @param {SimpleIdDto} simpleIdDto user Ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwner(submissionId: number, simpleIdDto: SimpleIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwner(submissionId, simpleIdDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateOwner']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary archive app
         * @param {number} submissionId id
         * @param {boolean} archive archive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveApp(submissionId: number, archive: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.archiveApp(submissionId, archive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary assign org
         * @param {number} submissionId id
         * @param {string} orgId org id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignOrg(submissionId: number, orgId: string, options?: any): AxiosPromise<void> {
            return localVarFp.assignOrg(submissionId, orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApp(submissionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteApp(submissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary download response pdf
         * @param {number} responseId id
         * @param {boolean} [translate] translate response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPdf(responseId: number, translate?: boolean, options?: any): AxiosPromise<File> {
            return localVarFp.downloadPdf(responseId, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get app details
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApp(submissionId: number, options?: any): AxiosPromise<GrantAppDetailDto> {
            return localVarFp.getApp(submissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get app summary
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSummary(submissionId: number, options?: any): AxiosPromise<GrantAppDto> {
            return localVarFp.getAppSummary(submissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get classie org types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassieOrgTypes(options?: any): AxiosPromise<Array<SimpleIdNameDto>> {
            return localVarFp.getClassieOrgTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of members for share or changeuser
         * @param {number} submissionId id
         * @param {GetMembersOperationEnum} operation operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers(submissionId: number, operation: GetMembersOperationEnum, options?: any): AxiosPromise<ApplicantOrgMemberListDto> {
            return localVarFp.getMembers(submissionId, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getRegions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get user submissions
         * @param {number} [startIndex] 
         * @param {GetSubmissionsSortByEnum} [sortBy] 
         * @param {GetSubmissionsSortOrderEnum} [sortOrder] 
         * @param {boolean} [archive] 
         * @param {number} [page] 
         * @param {string} [keyword] 
         * @param {string} [orgId] 
         * @param {number} [status] 
         * @param {string} [round] 
         * @param {string} [ownedBy] 
         * @param {string} [sharedWith] 
         * @param {string} [formName] 
         * @param {string} [response] 
         * @param {string} [submittedDateFrom] 
         * @param {string} [submittedDateTo] 
         * @param {string} [dueDateFrom] 
         * @param {string} [dueDateTo] 
         * @param {boolean} [isSimpleSearch] 
         * @param {number} [instanceId] 
         * @param {number} [classieOrgId] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmissions(startIndex?: number, sortBy?: GetSubmissionsSortByEnum, sortOrder?: GetSubmissionsSortOrderEnum, archive?: boolean, page?: number, keyword?: string, orgId?: string, status?: number, round?: string, ownedBy?: string, sharedWith?: string, formName?: string, response?: string, submittedDateFrom?: string, submittedDateTo?: string, dueDateFrom?: string, dueDateTo?: string, isSimpleSearch?: boolean, instanceId?: number, classieOrgId?: number, region?: string, options?: any): AxiosPromise<SubmissionListDto> {
            return localVarFp.getSubmissions(startIndex, sortBy, sortOrder, archive, page, keyword, orgId, status, round, ownedBy, sharedWith, formName, response, submittedDateFrom, submittedDateTo, dueDateFrom, dueDateTo, isSimpleSearch, instanceId, classieOrgId, region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get current user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of instances the user has applied for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInstances(options?: any): AxiosPromise<Array<InstanceDto>> {
            return localVarFp.getUserInstances(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove the org from the submission
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrg(submissionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeOrg(submissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary share app
         * @param {number} submissionId id
         * @param {ShareApplicationDto} shareApplicationDto user Ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareApp(submissionId: number, shareApplicationDto: ShareApplicationDto, options?: any): AxiosPromise<void> {
            return localVarFp.shareApp(submissionId, shareApplicationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Variation Response for the given GrantApp
         * @param {number} submissionId id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startVariationForm(submissionId: number, options?: any): AxiosPromise<Array<ResponseDto>> {
            return localVarFp.startVariationForm(submissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary change user
         * @param {number} submissionId id
         * @param {SimpleIdDto} simpleIdDto user Ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwner(submissionId: number, simpleIdDto: SimpleIdDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateOwner(submissionId, simpleIdDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary archive app
     * @param {number} submissionId id
     * @param {boolean} archive archive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public archiveApp(submissionId: number, archive: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).archiveApp(submissionId, archive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary assign org
     * @param {number} submissionId id
     * @param {string} orgId org id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assignOrg(submissionId: number, orgId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assignOrg(submissionId, orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete
     * @param {number} submissionId id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteApp(submissionId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteApp(submissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary download response pdf
     * @param {number} responseId id
     * @param {boolean} [translate] translate response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadPdf(responseId: number, translate?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadPdf(responseId, translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get app details
     * @param {number} submissionId id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApp(submissionId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApp(submissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get app summary
     * @param {number} submissionId id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAppSummary(submissionId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAppSummary(submissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get classie org types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClassieOrgTypes(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getClassieOrgTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of members for share or changeuser
     * @param {number} submissionId id
     * @param {GetMembersOperationEnum} operation operation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMembers(submissionId: number, operation: GetMembersOperationEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMembers(submissionId, operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get regions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRegions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRegions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get user submissions
     * @param {number} [startIndex] 
     * @param {GetSubmissionsSortByEnum} [sortBy] 
     * @param {GetSubmissionsSortOrderEnum} [sortOrder] 
     * @param {boolean} [archive] 
     * @param {number} [page] 
     * @param {string} [keyword] 
     * @param {string} [orgId] 
     * @param {number} [status] 
     * @param {string} [round] 
     * @param {string} [ownedBy] 
     * @param {string} [sharedWith] 
     * @param {string} [formName] 
     * @param {string} [response] 
     * @param {string} [submittedDateFrom] 
     * @param {string} [submittedDateTo] 
     * @param {string} [dueDateFrom] 
     * @param {string} [dueDateTo] 
     * @param {boolean} [isSimpleSearch] 
     * @param {number} [instanceId] 
     * @param {number} [classieOrgId] 
     * @param {string} [region] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSubmissions(startIndex?: number, sortBy?: GetSubmissionsSortByEnum, sortOrder?: GetSubmissionsSortOrderEnum, archive?: boolean, page?: number, keyword?: string, orgId?: string, status?: number, round?: string, ownedBy?: string, sharedWith?: string, formName?: string, response?: string, submittedDateFrom?: string, submittedDateTo?: string, dueDateFrom?: string, dueDateTo?: string, isSimpleSearch?: boolean, instanceId?: number, classieOrgId?: number, region?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSubmissions(startIndex, sortBy, sortOrder, archive, page, keyword, orgId, status, round, ownedBy, sharedWith, formName, response, submittedDateFrom, submittedDateTo, dueDateFrom, dueDateTo, isSimpleSearch, instanceId, classieOrgId, region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get current user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUser(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of instances the user has applied for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserInstances(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserInstances(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove the org from the submission
     * @param {number} submissionId id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeOrg(submissionId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removeOrg(submissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary share app
     * @param {number} submissionId id
     * @param {ShareApplicationDto} shareApplicationDto user Ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shareApp(submissionId: number, shareApplicationDto: ShareApplicationDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).shareApp(submissionId, shareApplicationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Variation Response for the given GrantApp
     * @param {number} submissionId id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public startVariationForm(submissionId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).startVariationForm(submissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary change user
     * @param {number} submissionId id
     * @param {SimpleIdDto} simpleIdDto user Ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateOwner(submissionId: number, simpleIdDto: SimpleIdDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateOwner(submissionId, simpleIdDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetMembersOperationEnum = {
    SHARE: 'share',
    CHANGE: 'change'
} as const;
export type GetMembersOperationEnum = typeof GetMembersOperationEnum[keyof typeof GetMembersOperationEnum];
/**
 * @export
 */
export const GetSubmissionsSortByEnum = {
    INSTANCE_NAME: 'INSTANCE_NAME',
    PUBLIC_ID: 'PUBLIC_ID',
    GRANT_ROUND: 'GRANT_ROUND',
    PROJECT_TITLE: 'PROJECT_TITLE',
    DUE_DATE: 'DUE_DATE',
    SUBMITTED_DATE: 'SUBMITTED_DATE',
    STATUS: 'STATUS'
} as const;
export type GetSubmissionsSortByEnum = typeof GetSubmissionsSortByEnum[keyof typeof GetSubmissionsSortByEnum];
/**
 * @export
 */
export const GetSubmissionsSortOrderEnum = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING'
} as const;
export type GetSubmissionsSortOrderEnum = typeof GetSubmissionsSortOrderEnum[keyof typeof GetSubmissionsSortOrderEnum];


